import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/node_modules/gatsby-theme-docz/src/base/Layout.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "contributing"
    }}>{`Contributing`}</h1>
    <p>{`Thank you for your interest in contributing to the `}<a parentName="p" {...{
        "href": "https://www.reactandtypescript.dev/"
      }}>{`React And TypeScript`}</a>{` unoffical documentation. I hope to team up with others to create the premier source of React and TypeScript documentation on the web.`}</p>
    <h2 {...{
      "id": "creating-issues"
    }}>{`Creating Issues`}</h2>
    <p>{`If you find a React and TypeScript example that would be an addition to this React and TypeScript documentation, please create an issue.`}</p>
    <p>{`When creating an issue, please include:`}</p>
    <ul>
      <li parentName="ul"><strong parentName="li">{`User Story`}</strong>{` - why does the reader want this information?`}</li>
      <li parentName="ul"><strong parentName="li">{`Acceptance Criteria`}</strong>{` - what examples need to be described for the reader to understand how to use React and TypeScript in the context described?`}</li>
      <li parentName="ul"><strong parentName="li">{`Notes`}</strong>{` (optional) - what resources can be leveraged to get this documentation written more quickly? Also, any extra notes go here.`}</li>
    </ul>
    <h2 {...{
      "id": "creating-pull-requests"
    }}>{`Creating Pull Requests`}</h2>
    <ol>
      <li parentName="ol">{`Confirm an issue exists for the pull request you are about to make. If no issue exists, `}<a parentName="li" {...{
          "href": "#creating-issues"
        }}>{`please create one`}</a>{`.`}</li>
      <li parentName="ol">{`Fork this repository.`}</li>
      <li parentName="ol">{`Create a branch specific to the section you are working on or adding. Push your work to that branch. Focus your work on that fix or feature.`}<ul parentName="li">
          <li parentName="ul"><a parentName="li" {...{
              "href": "https://chris.beams.io/posts/git-commit/"
            }}>{`Use good commit messages`}</a>{`.`}</li>
          <li parentName="ul">{`Name the branch appropriately.`}</li>
        </ul></li>
      <li parentName="ol">{`Submit a pull request against the `}<em parentName="li">{`master`}</em>{` branch.`}</li>
    </ol>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      